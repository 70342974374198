<template>
	<div class="virtual-bag-modal-content">
		<div class="banner-nav">
			<a class="banner-nav__prev" @click.prevent="showPrevious()">
				<inline-svg :src="require('../assets/left.svg')" width="25"></inline-svg>
			</a>
			<a class="banner-nav__close" @click.prevent="cancel()">
				<inline-svg :src="require('../assets/delete.svg')" width="25"></inline-svg>
			</a>
			<a class="banner-nav__next" @click.prevent="showNext()">
				<inline-svg :src="require('../assets/left.svg')" width="25"></inline-svg>
			</a>
		</div>
		<template v-if="item">
			<div class="banner-main">
				<div class="banner-img"><img :src="item.main_banner | imageUrl" /></div>
				<div class="banner-content clearfix">
					<h1>{{ item.title }}</h1>
					<h2>{{ item.subtitle }}</h2>
					<div class="content">
						<VueMarkdown v-if="item.main_description" :source="item.main_description" :prerender="prerender" />
					</div>
				</div>
			</div>
			<div class="banner-action">
				<a class="banner-action__like" @click.prevent="likeItem(item)">
					<inline-svg v-show="!item.liked" :src="require('../assets/heart.svg')" width="25"></inline-svg>
					<inline-svg v-show="item.liked" :src="require('../assets/heart-filled.svg')" width="25"></inline-svg>
				</a>
				<Link v-if="item.action_button" :link="item.action_button" :analytics="analytics" class="button is-primary">{{
					item.action_button.text ? item.action_button.text : item.title
				}}</Link>
			</div>
		</template>
	</div>
</template>

<script>
	import Link from './Link';
	import $http from '../utils/http';
	import VueMarkdown from 'vue-markdown-v2';
	import { prerender } from '../utils/markdown';

	export default {
		name: 'VirtualBagModal',
		components: { Link, VueMarkdown },
		props: {
			current: Number,
			items: Array,
		},
		data() {
			return {
				currentItem: 0,
			};
		},
		computed: {
			analytics() {
				return {
					event: 'gaEvent',
					eCategory: 'No Booth Event',
					eAction: 'Digital Congress Bag - Popup',
					eLabel: this.item.title ? this.item.title : null,
					Exhibitor: this.item.gtm_exhibitor ? this.item.gtm_exhibitor : '(not set)',
					Booth: '(not set)',
				};
			},
			item() {
				return this.items.find(e => e.id === this.currentItem);
			},
		},
		watch: {
			current() {
				this.currentItem = this.current;
			},
		},
		mounted() {
			this.currentItem = this.current;
			$http.post('/create-action', {
				category_type: 'congress_item_view',
				action_id: this.current,
			});
		},
		methods: {
			likeItem(item) {
				item.liked = !item.liked;
				if (item.liked === true) {
					$http.post(`/congress-items/${item.id}/likes`).then(
						() => {},
						() => {
							item.liked = !item.liked;
						}
					);
				} else if (item.liked === false) {
					$http.delete(`/congress-items/${item.id}/likes`).then(
						() => {},
						() => {
							item.liked = !item.liked;
						}
					);
				}
			},
			cancel() {
				this.$emit('close');
			},
			showPrevious() {
				let newItem;
				let index = this.items.findIndex(e => e.id === this.currentItem);
				if (index > 0) {
					newItem = this.items[index - 1].id;
				} else {
					newItem = this.items[this.items.length - 1].id;
				}

				this.$emit('close', newItem);
			},
			showNext() {
				let newItem;
				let index = this.items.findIndex(e => e.id === this.currentItem);
				if (index + 1 < this.items.length) {
					newItem = this.items[index + 1].id;
				} else {
					newItem = this.items[0].id;
				}
				this.$emit('close', newItem);
			},
			prerender: prerender,
		},
	};
</script>
